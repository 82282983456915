
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,400;0,600;1,200;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap');


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  color: white;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  background: "var(--primary)";
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

h2{
  font-size: 2.8em;
  font-weight: 900;
}
/* @font-face {
  font-family: "'Montserrat'";
  src: local("'Montserrat'"), url(font.woff) format("Montserrat");
} */

body {
  margin: 0;
  font-family: "'Montserrat'", "Source Sans Pro", "coder", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: "Montserrat", "source-code-pro", Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html {
  background-color: "#4C4C4C";
}

div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

/* HEADER */
.header{
  display: flex;
  justify-content: right;
  width: 100vw;
  height: 90px;
  padding: 0 10px;
  /* background: var(--primary); */
  color: var(--primary);
}

.headerItems{
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 0 10px;
    transition: all ease-in-out 100ms;
    z-index: 100;
}

.brand_logo{
  margin: 20px 0 0 25px;
  position: absolute;
  /* height: 120px; */
  /* width: 10vw; */
  max-width: 10vw;
  left: 0;

  transition: all ease-in-out 300ms;
}
.brand_logo:hover{
  transform: scale(1.2);
}

/* NAVBAR */
.menus{
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 100ms;
  font-size: 1.1em;
}

.menu-items, .submenu-items{
  margin: 0 3px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  transition: ease-in-out 100ms;

  height: 30px;
  padding: 8px 10px;
}
.menu-items a, .submenu-items a{
  text-decoration: none;
  font-size: 0.8em;
}
.menu-items button, .submenu-items button {
  color: white;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  font-size: 0.9em;
  font-weight: 600;
}

.menu-items:hover, .submenu-items:hover{
  background-color: gray;
}

.dropdown{
  display: none;
  position: absolute;
  left: auto;
  top: 30px;
  box-shadow: 0 10px 15px -3px rgba(253, 253, 253, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  border-radius: 0.5rem;
}

.dropdown.show{
  display: block;
  background-color: black;
}

@media (max-width: 950px) {
  .menus{
    flex-direction: column;
  }
  
  .menu{
    background: var(--primary);
    width: 100%;
    flex-direction: column;
    position: absolute;
    padding-top: 8px;
    align-items: center;
    left: 0;
    font-size: 2.2em;
  }
  .menu-items{
    font-size: 0.8em;
    margin-bottom: 4px;
  }
  .submenu-items{
    height: auto;
    width: auto;
  }
  .menu-items a{
    font-size: 0.8em;
  }
}

/* Home */
.hero{
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 0 0 60px 0;
  text-align: center;
  position: relative;
  /* background: url("https://i.ibb.co/rZLnp1N/website-banner.png"); */
  /* background-position:center; */
  /* background-attachment: fixed; */
  /* height: 300px; */
  /* width: 80%; */
}
.hero_container{
  margin: 30px 0 10px 0;
  /* background: rgba(0, 0, 0, 0.85); */
  width: auto;
  padding: 10px;
  border-radius: 10px;
  
}
.hero h2{
  font-size: 2.8em;
  font-style: italic;
  font-weight: 900;
  letter-spacing: normal;
}

.hero h2, .hero p{
  z-index: 2;
}

.hero img{
  width: 90%;
  top: 0;
  left: 0;

  z-index: 0;
}

.home_first_row{
  display: flex;
  justify-content: space-between;
}
.home_first_row h2{
  font-size: 2.9em;
  font-weight: 700;
  margin-bottom: 30px;
}
.home_first_row p, .hero p{
  font-size: 1.1em;
  font-weight: 400;
  line-height: 2;
}
.span{
  color: var(--yellow)
}

.first_row_left{
  width: 50%;
  margin-top: 20px;
  margin-left: 5vw;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.first_row_left h2{
  width: 100%;
}
.first_row_right{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5vw;
  /* background-color: rgba(48, 64, 145, 0.753); */
}
.home_first_row img{
  width: 100%;
}

.mint_btn2{
  margin: 50px 0;

}

.mint_link, .mint_link2{
  margin-top: 90px;
  padding: 10px 25px;
  width: 180px;
  font-size: 1.6em;

  border-radius: 10px;

  cursor: pointer;

  text-align: center;
  background: var(--gradient-yellow);
}
.mint_link a, .mint_link2 a{
  text-decoration: none;
  font-weight: 800;
}
.mint_link2{
  margin: 30px 0 50px 0;
}

/* mint page */
.mint_pre_wrapper{
  height: 700px;

  background-image: url("./img/mint_bg.png");
  background-size: cover;
  background-position: bottom;
}
.mint_wrapper{
  display: flex;
  width: 100%;
  margin-top: 60px;
}
.mint_l{
  width: 50%;
  display: flex;
  justify-content: center;
}
.mint_l_container{
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mint_description{
  width: 80%;
  text-align: justify;
  display: none;
}
.img_and_vid{
  margin-top: 30px;
}
.mint_r{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}
.mint_box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 65%;
  padding: 20px 30px;
  border: 1px solid white;
  border-radius: 10px;
  background-color: #242424;

  transform: scale(0.8);
}
.max_btn{
  padding: 5px 10px;
  background: white;
  font-size: 1.1em;
  font-weight: 600;
  border: none;
  border-radius: 10%;
}

@media only screen and (max-width: 750px){
  .hero h2{
    font-size: 1.8em;
  }

  .first_row_right{
    margin-right: 0;
  }
  .mint_link{
    margin: 30px 0;
  }
}

/* PHASE */
.phase_item{
  width: 55%;
}

.h1{
  font-size: 2em;
}

.description{
  padding: 8px;
  border-radius: 10px;
  background-color: var(--secondary);
  margin-bottom: 10px;
  transition: all ease-in-out 200ms;
}

.description:hover{
  transform: scale(1.3);
}

/* ABOUT */
.about_wrapper, .d2e_wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  padding-top: 50px;
  border-top: 1px solid gray;
  width: 100%;

  
}
.about_container{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  width: 100%;
  height: auto;
  padding: 20px 0;
  background-size: cover;
}
.about_title{
  margin-bottom: 20px;
}
.about_title2{
  align-self:flex-start;
}
.about_title{
  font-weight: 600;
  font-size: 2em;
}
.about_wrapper p, .d2e_wrapper p{
  width: 100%;
}
.about1{
  width: 680px;
  font-size: 0.7em;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 200;
}
.about2{
  width: 680px;
  font-size: 1.2em;
  margin-bottom: 20px;
  font-weight: 400;
  line-height: 1.9;
  text-align: justify;
}
.about_img{
  width: 70vw;
}
.about_img img{
  width: 100%;
}
.about_text{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 70%;
  text-align: center;
}

/* TOKENOMICS */


.tokenomics_img{
  width: 60vw;
  transition: all ease-in-out 200ms;
}
.tokenomics_img:hover{
  transform: scale(1.01);
}

/* D2E specific - there's also rules for d2e in the About section - scroll up */
.d2e{
  background-image: url('./img/doge2earn_bg.png');
  background-size: cover;
  background-attachment: fixed;
}

.d2e_wrapper{
  position: relative;
}

.d2e_img{
  width: 30vw;
}

.wods_band{
  position: absolute;
  bottom: 0;
  right: 0;

  width: 600px;
  height: auto;
}

/* FAQ */
.faq_wrapper{
  display: flex;
  flex-direction: column;
}

.faq_wrapper h2{
  font-size: 2em;
  text-align: center;
  margin-bottom: 30px;
}

.faq_wrapper .Collapsible {
  background-color: #242527;
}

/* The content within the collaspable area */
.faq_wrapper .Collapsible__contentInner {
  padding: 10px;
  font-size: 1.2em;
  font-weight: 400;
  border: 1px solid lightGrey;
  border-top: 0;
}

.faq_wrapper p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
}
.faq_wrapper .Collapsible__contentInner:last-child {
      margin-bottom: 0;
}

/* The link which when clicked opens the collapsable area */
.faq_wrapper .Collapsible__trigger {
  display: block;
  font-weight: 400;
  font-size: 1.4em;
  text-decoration: none;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #2c2c2c;
  color: var(--yellow);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.faq_wrapper .Collapsible__trigger:after {
    content: '↑';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
}

.faq_wrapper .Collapsible__trigger.is-open:after {
      transform: rotateZ(180deg);
      /* content: 'Less'; */
    }

.faq_wrapper .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey;
}

.faq_wrapper .CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
  
  
}

.faq_wrapper .CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.faq_wrapper .Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black;
}

/* Collapsible */

.switch{
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-top: 10px;
}

.switch input{
  opacity: 0;
  width: 0;
  height: 0;
}

.slider{
  position: absolute;
  cursor: pointer;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  border-radius: 2px;

  background-color: gray;
  transition: 400ms;

}

.slider:before{
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 400ms;
}

input:checked + .slider{
  background-color: #2196f3;
}

input:checked + .slider:before{
  transform: translateX(26px);
}

.slider.rounded{
   border-radius: 34px;
}

.slider.rounded:before{
  border-radius: 50%;
}

/* ROADMAP */
.roadmap{
  padding: 30px 0;
  margin: 80px 40px 0 40px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('https://i.ibb.co/Xxdqhq8/STREETMAP.png');
  background-size: cover;
  overflow: hidden;

}
.roadmap h2{
  font-size: 2.5em;
  font-weight: bold;
  font-style: italic;
  text-align: center;
  margin-left: 12vw;
}
.roadmap h3{
  font-size: 1.6em;
  text-align: center;
}
.roadmap li, .roadmap p{
  font-size: 1.4em;
  text-align: justify;
}
.roamdap_content{
  width: 50%;
}

.visual_rm_container{
  display: flex;
  position: relative;
  flex-direction: row;
  margin-top: 30px;
  width: 90%;
  height: max-content;
  justify-content: space-evenly;
}

.rm_column2{
  display: flex;
  flex-direction: column;
  position: relative;
}


.rm_phase {
  width: 30vw;
  height: fit-content;
  padding: 10px;
  /* border: 1px solid #FFD439; */
  border-radius: 10px;
  position: relative;
}
.rm_phase_line{
  height: 4px;
  width: 26px;
  background: var(--yellow);
  position: relative;
  top: 40px;
  left: -15px;
}

.rm_phase_line::before{
  width: 14px;
  height: 14px;
  content: '';
  position: absolute;
  border-radius: 50%;
  background: var(--yellow);
  top: -5px;
  left: -40%;
}

.rm_phase1 h2{
  text-align: left;
}
.rm_phase p{
  font-weight: 400;
  line-height: 1.9;
  font-size: 1.2em;
  text-align: justify;
}

/* Roadmap images */
.rm_img1, .rm_img2{
  width: 160px;
  height: auto;
  position: absolute;
}
.rm_img3, .rm_img4{
  width: 120px;
  height: auto;
  position: absolute;
}
.rm_img1{
  right: 130%;
  top: -170px;
}
.rm_img2{
  left: 130%;
}
.rm_img3{
  right: 130%;
  top: 280px;
}
.rm_img4{
  left: 130%;
  top: 550px;
}

.rm_bg_img{
  position: absolute;
  height: 100vh;
  top: -90px;
  left: -60px;
}

.rm_phase_wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}
.rm_phase_logo{
  width: 120px;
  margin-right: 20px;
}

.roadmap p {
  margin: 10px 0;
  font-size: 1em;
  line-height: 20px;
}

/* Roadmap Collapsibility */
.roadmap .Collapsible {
  background-color: #242527;
}

/* The content within the collaspable area */
.roadmap .Collapsible__contentInner {
  padding: 10px 20px;
  font-size: 1.2em;
  font-weight: 400;
  border: 1px solid var(--yellow);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 0;
}

.roadmap .Collapsible__contentInner:last-child {
      margin-bottom: 0;
}

/* The link which when clicked opens the collapsable area */
.roadmap .Collapsible__trigger {
  display: block;
  font-weight: 800;
  font-size: 1.8em;
  text-align: center;
  text-decoration: none;
  position: relative;
  border: 5px solid var(--yellow);
  border-radius: 10px;
  padding: 10px;
  background: #2c2c2c;
  color: var(--yellow);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.roadmap .Collapsible__trigger:after {
    content: '↓';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
}

.roadmap .Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
  /* content: 'Less'; */
}

.roadmap .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey;
}

.roadmap .CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.roadmap .CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.roadmap .Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #CBB700;
  color: black;
}

/* Partners */
.partners{
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 90%;
  margin: 10px 0;
}
.partners h2{
  font-size: 2.5em;
  margin-bottom: 20px;
}
.partners_container{
  /* display: grid;
  gap: 4vw; */
  /* display: flex; */
  /* align-items: space; */
  /* justify-items: center; */
  /* justify-content: space-evenly; */
  /* grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); */
  width: 100%;

  padding: 40px;
  border-radius: 20px;

  background-color: rgb(43, 43, 43);
}
.row1{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 60px;
  padding: 0 60px;
}
.row2{
  display: flex;
  justify-content: space-evenly;
}
.partner_item{
  display: flex;
  justify-content: center;
  width: 80px;
  height: 80px;
}

.after_rm_text{
  width: 60%;
  margin-top: 100px;
}

.after_rm_text p{
  font-size: 1.2em;
  font-weight: 400;
}

@media only screen and (max-width: 1100px){
  .mint_pre_wrapper{
    height: auto;
  }
  .mint_wrapper{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mint_l, .mint_r{
    width: 90%;
  }
}

/* Mobile Roadmap */
@media only screen and (max-width: 1032px){
  .visual_rm_container{
    flex-direction: column;
    align-items: center;
  }

  .dashed_line, .dashed_line_2 {
    display: none;
  }

  .middle_element_rm{
    margin: 0;
  }

  .rm_phase1, .rm_phase2, .rm_phase3{
    width: 60vw;
    margin: 10px 10px;
  }

  .after_rm_text{
    width: 90%;
  }
  
}


/* Team */
.team_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width: 95%;
  padding: 20px;
}
.team_container h2{
  font-size: 2em;;
  margin-bottom: 50px;
}

.team_members{
  display: grid;
  gap: 4vw;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  width: 90%;
}

.member_item{
  width: auto;
  display: flex;
  height: 350px;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  text-align: left;
}

.member_container{
  display: flex;
  align-items: center;
}
.member_container a{
  display: flex;
  justify-content: center;
  align-items: center;
}

.member_item span{
  font-size: 1.2em;
}
.member_item img{
  width: 80%;
  fill: #1d9bf0;
}

.member_item h3{
  font-size: 1.8em;
  margin: 5px 10px 5px 0;

}

.team_desc{
  font-weight: 400;
  font-size: 0.8;
  margin-top: 6px;
  width: 82%;
}

.team_role{
  text-align: center;
}

/* Team Collapsible */
.team_container .Collapsible {
  background-color: #242527;
}


/* The content within the collaspable area */
.team_container .Collapsible__contentInner {
  padding: 10px;
  font-weight: 400;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 0;
}


.team_container .Collapsible__contentInner:last-child {
      margin-bottom: 0;
}

/* The link which when clicked opens the collapsable area */
.team_container .Collapsible__trigger {
  display: block;
  font-weight: 600;
  font-size: 1.2em;
  text-decoration: none;
  position: relative;
  padding: 10px;
  background: #2c2c2c;
  color: var(--yellow);
}

.team_container .Collapsible__trigger:after {
    content: '+';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
}

.team_container .Collapsible__trigger.is-open:after {
      /* transform: rotateZ(180deg); */
      content: '-';
    }

.team_container .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey;
}

.team_container .CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;  
}

.team_container .CustomTriggerCSS--open {
  background-color: darkslateblue;
}

.team_container .Collapsible__custom-sibling {
  padding: 5px;
  font-size: 1.2em;
  background-color: #CBB700;
  color: black;
}


/* Footer */
.footer{
  display: flex;
  justify-content:center;
  height: auto;
  margin-top: 10px;
  text-align: center;
  border-top: 1px solid rgb(167, 167, 167);
  padding: 20px 0;
  position: relative;
}
.footer_menu{
  margin: 0 30px;
  text-align: left;
}
.footer_links{
  display: flex;
  justify-content:center;
  text-align: center;
  position: relative;
}
.footer a{
  text-decoration: none;
  color: white;
}
.footer_credit{
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 0.9em;
}
.footer_copyright{
  position: absolute;
  left: 20px;
  bottom: 20px;
  font-size: 0.9em;
}

@media only screen and (max-width: 750px){
  .home_first_row{
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  .first_row_left{
    width: 90%;
  }
  .about_wrapper, .d2e_wrapper{
    width: 100%;
  }

  .about_wrapper p, .about_wrapper li, .d2e_wrapper p, .d2e_wrapper li{
    font-size: 0.9em;
    width: 88%;
  }
  .about_text ul{
    width: 80%;
  }

  .about_container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .about_img{
    width: 80%;
    display: flex;
    justify-content: center;
  }
  .about_img img{
    width: 60%;
    align-self: center;
  }
  .about_text{
    width: auto;
    margin-top: 0;
  }
  .rm_phase{
    width: 60vw;
  }

  /* Footer */
  .footer{
    flex-direction: column;
    align-items: center;
  }
  .absolute_elements_footer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
  }
  .footer_copyright{
    position: static;

    width: 90px;
    font-size: 0.8em;
    text-align: left;
  }
  .footer_credit{
    position: static;

    width: 100px;
    margin-right: 10px;
    text-align: left;
    font-size: 0.8em;
  }
}

/* NFT HOLDINGS */
.stake_main, .stake_main_wrapper, .staking_wrapper, .staking_container{
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
}

.stake_main_wrapper, .staking_container{
  margin-top: 30px;
}

.nft_holdings_container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 5vw;

  justify-content: center;
  align-items: center;



}

.nft_holdings{
  height: 120px;
  width: 120px;
}

.nft_item{
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

}

/* LAUNCHPAD */


.launchpad_wrapper{
  margin-top: 60px;
  margin-left: 5%;
  display: flex;
  align-self: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-image: url('./img/wods-launchpad-bg.png');
  background-size: cover;
  /* background-attachment: fixed; */
  width: 90%;
}
.launchpad_wrapper h2{
  color: var(--yellow);
  font-weight: 900;
  margin-top: 50px;
}
.launchpad_wrapper h3{
  font-weight: 700;
  font-size: 1.4em;
}
.launchpad_subtitle{
  letter-spacing: 0.3em;
}
.launchpad_subtitle::after{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  content: "";
  height: 3px;
  width: 100%;
  background-color: rgb(222, 221, 221);
}
.launchpad_subtitle2{
  width: 500px;
  font-size: 1.7em;
  font-weight: 300;
  color: var(--yellow);
  letter-spacing: 0.1em;
  text-align: center;
}
.hero_launchpad{
  display: flex;
  justify-content: space-around;
  align-items: center;

  margin-top: 40px;
  width: 80%;
  height: 190px;
 
  background-color: rgba(253, 253, 253, 0.08);
  border-radius: 13px;
}
.hero_launchpad_container{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.hero_launchpad p{
  margin-top: 10px;
  font-size: 1.3em;
  font-weight: 200;
  letter-spacing: 0.1em;
}
.hero_launchpad button{
  padding: 5px 10px;
  color:rgba(0, 0, 0, 0.844);
  font-size: 1.5em;

  border-radius: 12px;
  border: none;
  background:var(--brownish);
  font-weight: 900;
  transform: scale(1.3);
  cursor: pointer;
  transition: all ease-in-out 400ms;
}
.hero_launchpad button:hover {
  transform: scale(1.4);
}
.hero_launchpad button:active {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.launchpad_main{
  margin-top: 60px;
  width: 80%;
}
.launchpad_main h3, .launchpad_bottom h3{
  color:var(--brownish);
  font-size: 2.4em;
  text-align: start;
  margin-bottom: 10px;
}
.launchpad_main h3::before{
  content: "";
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color:var(--brownish);
}
.launchpad_main p, .launchpad_bottom p{
  text-align: start;
  font-size: 1.3em;
  font-weight: 700;
  margin: 10px 10px 0 10px;
}

.launchpad_tiers{
    display: grid;
    gap: 4vw;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    width: 85%;
    margin-top: 60px;
}

.lp_item{
  position: relative;
  display: flex;
  justify-content: center;
  transition: all ease-in-out 300ms;
}
.lp_item:hover{
  transform: scale(1.05);
}

.lp_item_container{
  position: absolute;
  width: 220px;
  top: 35%;

  display: flex;
  flex-direction: column;

  font-size: 1.1em;
  font-weight: 600;
}
.lp_item_container p{
  margin: 8px 0;
}
.lp_item_container span{

  color: var(--brownish);
}

.launchpad_bottom{
  margin-top: 60px;
  margin-bottom: 60px;
  width: 80%;
  padding: 40px;

  background-color: rgba(253, 253, 253, 0.08);
  background-image: url("./img/wods-launchpad-bg2.png");
  background-size:cover;
  background-position: top;
  border-radius: 13px;

  height: 300px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.launchpad_bottom span{
  color: white;
}
.lp_poolweight{
  margin-top: 0 !important;
}
.lp_extra{
  font-size: 0.8em;
  color: var(--brownish);
}

.launchpad_bottom_lasth3{
  text-align:center; 
  font-size:2.6em;
  margin-top:40px;
}

@media only screen and (max-width: 1250px){
  .member_item{
    height: 400px;
  }

  /* partners */
  .partners{
    width: 100%;
  }
.row1, .row2{
  padding: 0 10px;
  justify-content: space-between;
}
.partner_item{
  width: 60px;
  height: 60px;
}
.launchpad_wrapper{
width: 100%;
margin-left: 0;
}
}

@media only screen and (max-width: 750px){
  .team_container .Collapsible{
    width: 80%;
  }
  .roadmap{
    width: 100%;
  }

  .hero_launchpad{
    flex-direction: column;
  }
  .hero_launchpad button{
    transform: scale(1.0);
  }
  .launchpad_bottom{
    font-size: 0.8em;
  }
  .launchpad_bottom h3{
    text-align: center;
  }

  .mint_box{
    width: 95%;
  }
}

@media only screen and (max-width: 584px){
  .member_item{
    height: auto;
  }
  .d2e{
    background-image: url('./img/doge2earn_bg.png');
    background-size: cover;
    background-attachment:unset;
  }
}

@media only screen and (max-width: 584px){
  .mint_l, .mint_r{
    width: 100%;
  }

  .mint_box{
    width: 100%;
  }

  .launchpad_bottom_lasth3{
    margin-top: 0;
  }
  .partner_item{
    transform: scale(0.8);
  }
}